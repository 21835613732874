<template>
  <div>
    <b-container>
      <template v-if="isMobile(true)">
        <div class="">
          <b-row class="MobMiniMen tRowTopMob">
            <b-col lg='10' md='4' cols='12' class="titleMini-sec">
              <span class="FirstBreadCrum">
                <template v-if="$route.name == 'Schedule'">
                  <b-link :href="firstCrumUrl" class="crumLinks">
                    <span>
                    <template v-if="config.VUE_APP_TOUR == 'aus'">
                      <img class="tourLogo hideMob" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/PGA_TOUR_LOGO_PRI_RGB_POS_ISO.jpg">
                    </template>
                    <template v-else-if="config.VUE_APP_TOUR == 'wpg'">
                      <img class="tourLogo hideMob" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/WPGA_logo_Blue.png">
                    </template>
                    <template v-else-if="config.VUE_APP_TOUR == 'snr'">
                      <img class="tourLogo hideMob" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/LegendsTourLogoPlain1.jpeg">
                    </template>
                    <template v-else-if="config.VUE_APP_TOUR == 'all'">
                      <img class="tourLogo hideMob" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/PGA_PAS_ADI_LOGO_RGB_POS.png">
                    </template>
                    <template v-else-if="config.VUE_APP_TOUR == 'ppc'">
                      <img class="tourLogo hideMob" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/PGA_PC_LOGO_RGB_POS.jpg">
                    </template>
                    <template v-else-if="config.VUE_APP_TOUR == 'trn'">
                      <img class="tourLogo hideMob" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/pga-tour-logo-card-no-isps-594.jpg">
                    </template>
                    {{FirstCrum }} Schedule 
                    </span>
                  </b-link>
                </template>
                <template v-else>
                  <!-- Schedule > Tournament > Reports -->
                  <b-link :href="firstCrumUrl" class="crumLinks" target="_top">
                    {{FirstCrum }} Schedule
                  </b-link>
                  <span>
                    <img class="arrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/Screenshot-2024-08-08-114110-copy.png">
                  </span>
                  <template v-if="$route.name == 'tournament'">
                    <span class="secondScrum">
                      <b-link :href="tournamentCrum" class="crumLinks"  target="_top">
                        <template>{{TournmentName}}</template> 
                      </b-link>
                    </span> 
                  </template>
                  <template v-else-if="$route.name == 'reports'">
                    <span class="secondScrum">
                      <b-link :href="TournmentLink" class="crumLinks"  target="_top">
                        <template>{{TournmentName}}</template> 
                      </b-link>
                    </span> 
                    <span >
                      <img class="arrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/Screenshot-2024-08-08-114110-copy.png">
                    </span>
                    <span class="secondScrum">
                      <b-link :href="'/'" class="crumLinks">
                        <template >{{title}}</template>
                      </b-link>
                    </span> 
                  </template>
                  <template v-else-if="$route.name == 'playerprofile'">
                    <span class="secondScrum">
                      <b-link :href="'/'" class="crumLinks"  target="_top">
                        <template>Profiles</template> 
                      </b-link>
                    </span> 
                    <span >
                      <img class="arrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/Screenshot-2024-08-08-114110-copy.png">
                    </span>
                    <span class="secondScrum">
                      <b-link :href="PlayerLink" class="crumLinks"  target="_top">
                        <template >{{PlayerName}}</template>
                      </b-link>
                    </span> 
                  </template>
                  <template v-else>
                    <span class="secondScrum" v-if="clearData()">
                      <b-link :href="TournmentLink" class="crumLinks"  target="_top">
                        <template>{{TournmentName}}</template> 
                      </b-link>
                    </span> 
                    <span v-if="clearData()">
                      <img class="arrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/Screenshot-2024-08-08-114110-copy.png">
                    </span>
                    <span class="secondScrum">
                      <template v-if="SecondCrum == 'oom'">
                        <b-link
                          :to="{
                            name: 'oom',
                            query: { id: seasonCode , tour: tour  },
                          }"
                           target="_top"
                          class="crumLinks"
                        >
                          <template>Order of Merit</template>
                        </b-link>
                      </template>
                      <template v-else>
                        <b-link :href="secondCrumUrl" class="crumLinks"  target="_top">
                          <template>{{SecondCrum}}</template>
                        </b-link>
                      </template>
                    </span> 
                  </template>
                </template>
              </span>
            </b-col>
            <!-- <b-col lg='1' md='2' cols='4'>
              <b-nav-item class="menuLink" to="/news">News</b-nav-item>
            </b-col> -->
          </b-row>
          <b-row class="buttonMargin">
            <b-col>
              <b-button :href="firstCrumUrl"  target="_top" class="mobBtn">
                Schedule
              </b-button>
            </b-col>
            <b-col>
              <b-button class="mobBtn" :to="{
                name: 'oom',
                query: { id: seasonCode , tour: tour  },
              }"
               target="_top"
              >
                Order of Merit
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
      <template v-else>
        <div class="tRowTop">
          <b-row class="MobMiniMen">
            <b-col lg='10' md='4' cols='12' class="titleMini-sec">
              <span class="FirstBreadCrum">
                <template v-if="$route.name == 'Schedule'">
                  <b-link :href="firstCrumUrl" class="crumLinks">
                    <span>
                    <template v-if="config.VUE_APP_TOUR == 'aus'">
                      <img class="tourLogo hideMob" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/PGA_TOUR_LOGO_PRI_RGB_POS_ISO.jpg">
                    </template>
                    <template v-else-if="config.VUE_APP_TOUR == 'wpg'">
                      <img class="tourLogo hideMob" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/WPGA_logo_Blue.png">
                    </template>
                    <template v-else-if="config.VUE_APP_TOUR == 'snr'">
                      <img class="tourLogo hideMob" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/LegendsTourLogoPlain1.jpeg">
                    </template>
                    <template v-else-if="config.VUE_APP_TOUR == 'all'">
                      <img class="tourLogo hideMob" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/PGA_PAS_ADI_LOGO_RGB_POS.png">
                    </template>
                    <template v-else-if="config.VUE_APP_TOUR == 'ppc'">
                      <img class="tourLogo hideMob" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/PGA_PC_LOGO_RGB_POS.jpg">
                    </template>
                    <template v-else-if="config.VUE_APP_TOUR == 'trn'">
                      <img class="tourLogo hideMob" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/pga-tour-logo-card-no-isps-594.jpg">
                    </template>
                    {{FirstCrum }} Schedule 
                    </span>
                  </b-link>
                </template>
                <template v-else>
                  <!-- Schedule > Tournament > Reports -->
                  <b-link :href="firstCrumUrl" class="crumLinks"  target="_parent">
                    {{FirstCrum }} Schedule
                  </b-link>
                  <span>
                    <img class="arrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/Screenshot-2024-08-08-114110-copy.png">
                  </span>
                  <template v-if="$route.name == 'tournament'">
                    <span class="secondScrum">
                      <b-link :href="tournamentCrum" class="crumLinks"  target="_parent">
                        <template>{{TournmentName}}</template> 
                      </b-link>
                    </span> 
                  </template>
                  <template v-else-if="$route.name == 'reports'">
                    <span class="secondScrum">
                      <b-link :href="TournmentLink" class="crumLinks"  target="_parent">
                        <template>{{TournmentName}}</template> 
                      </b-link>
                    </span> 
                    <span >
                      <img class="arrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/Screenshot-2024-08-08-114110-copy.png">
                    </span>
                    <span class="secondScrum">
                      <b-link :href="'/'" class="crumLinks"  target="_parent">
                        <template >{{title}}</template>
                      </b-link>
                    </span> 
                  </template>
                  <template v-else-if="$route.name == 'playerprofile'">
                    <span class="secondScrum">
                      <b-link :href="'/'" class="crumLinks"  target="_parent">
                        <template>Profiles</template> 
                      </b-link>
                    </span> 
                    <span >
                      <img class="arrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/Screenshot-2024-08-08-114110-copy.png">
                    </span>
                    <span class="secondScrum">
                      <b-link :href="PlayerLink" class="crumLinks"  target="_parent">
                        <template >{{PlayerName}}</template>
                      </b-link>
                    </span> 
                  </template>
                  <template v-else>
                    <span class="secondScrum" v-if="clearData()">
                      <b-link :href="TournmentLink" class="crumLinks"  target="_parent">
                        <template>{{TournmentName}}</template> 
                      </b-link>
                    </span> 
                    <span v-if="clearData()">
                      <img class="arrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/Screenshot-2024-08-08-114110-copy.png">
                    </span>
                    <span class="secondScrum">
                      <template v-if="SecondCrum == 'oom'">
                        <b-link
                          :to="{
                            name: 'oom',
                            query: { id: seasonCode , tour: tour  },
                          }"
                          class="crumLinks"
                           target="_parent"
                        >
                          <template>Order of Merit</template>
                        </b-link>
                      </template>
                      <template v-else-if="SecondCrum == 'fullReports'">
                        <b-link :href="secondCrumUrl" class="crumLinks"  target="_parent">
                          <template>Reports</template>
                        </b-link>
                      </template>
                      <template v-else>
                        <b-link :href="secondCrumUrl" class="crumLinks"  target="_parent">
                          <template>{{SecondCrum}}</template>
                        </b-link>
                      </template>
                    </span> 
                  </template>
                </template>
              </span>
            </b-col>
            <template v-if="$route.name == 'reports'">
              <b-col lg='2' md='2' cols='12'>
                <b-nav-item
                  class="menuLink"
                  :href="firstCrumUrl"
                   target="_parent"
                  >Schedule</b-nav-item
                >
              </b-col>
            </template>
            <template v-else>
              <b-col lg='1' md='2' cols='6' class="noPadRight">
                <b-nav-item
                  class="menuLink righMove"
                  :href="firstCrumUrl"
                  target="_parent"
                  >Schedule</b-nav-item
                >
              </b-col>
              <b-col lg='1' md='2' cols='6' class="noPadRight">
                <b-nav-item
                  class="menuLink"
                  :to="{
                    name: 'oom',
                    query: { id: seasonCode , tour: tour  },
                  }"
                  target="_parent"
                  >Order of Merit</b-nav-item
                >
              </b-col>
            </template>
            <!-- <b-col lg='1' md='2' cols='4'>
              <b-nav-item class="menuLink" to="/news">News</b-nav-item>
            </b-col> -->
          </b-row>
        </div>
      </template>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "miniMenu",
  props:['config', 'seasonCode', 'tour', 'tournamentName', 'title'],
  data() {
    return {
      FirstCrum: process.env.VUE_APP_BREAD_CRUM_NAME,
      firstCrumUrl: process.env.VUE_APP_FIRST_CRUM_URL,
      tournamentCrum: this.$route.fullPath,
      SecondCrum : this.$route.name,
      secondCrumUrl: this.$route.path,
      TournmentName: localStorage.getItem("Tournament Name"),
      TournmentLink: localStorage.getItem("Tournament Link"),
      PlayerName: localStorage.getItem("Player Name"),
      PlayerLink: localStorage.getItem("Player Link")
    }
  },
  methods:{
    clearData() {
      if (this.$route.name == 'oom') {
        localStorage.clear('Tournament Name');
      } 
    },
    isMobile() {
      if (screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.clearData();
  }
};
</script>

<style scoped>
.btn-secondary:focus, .btn-secondary.focus {
    color: #fff!important;
    background-color: #003C52;
    border-color: #003C52;
    box-shadow: 0 0 0 0rem rgba(130, 138, 145, 0.5);
}
/* a.btn.mobBtn.router-link-active.btn-secondary {
    background-color: #003C52;
    border-color: #003C52;
    color: #fff !important;
} */
a.btn.mobBtn.router-link-focus.btn-secondary {
    background-color: #003C52;
    border-color: #003C52;
    color: #fff !important;
}
.btn-secondary:hover {
    background-color: #003C52;
    border-color: #003C52;
    color: #fff !important;
}
.buttonMargin {
  margin-bottom: 20px;
}
.tRowTop {
  border-bottom: 0px solid #000;
  padding: 2px 0;
  /* background: rgba(0, 0, 0, 0.04); */
}
.tRowTopMob {
  border-bottom: 0px solid #000;
  padding: 2px 0;
  background: rgba(0, 0, 0, 0.04);
}
.mobBtn {
  background: transparent;
  line-height: 26px;
  height: 38px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
  margin-top: 20px;
  width: 100%;
  color: #ffffff!important;
  background-color: #000;
}
li.nav-item.menuLink.righMove {
  text-align: center;
}
::v-deep .noPadRight.col-md-2.col-lg-1.col-6 {
  padding-right: 0;
  padding-left: 0;
}
::v-deep .noPadRight.col-md-2.col-lg-2.col-6 {
  padding-right: 0;
  padding-left: 0;
}
.tourLogo {
  padding-right: 10px;
  mix-blend-mode: multiply;
}
img.arrow {
  width: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
a.crumLinks {
  color: #000!important;
  text-transform: capitalize;
}
a.crumLinks:hover {
  color: #003C52!important;
  text-decoration: none;
}
/* span.secondScrum:before {
  content: url('https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/Screenshot-2024-08-08-114110-copy.png');
  width: 10px;
} */
span.FirstBreadCrum {
  font-size: 14px;
  position: relative;
  top: 2px;
}
.titleMini-sec {
  /* padding-left: 0px; */
}
::v-deep .tRow {
  border-bottom: 0px solid #000;
  /* padding: 15px 0; */
  padding: 2px 0!important;
  background: rgba(0, 0, 0, 0.04);
}
.titleMainTour {
  color: #000;
  margin: 0;
  font-size: 10pt;
  font-weight: 500;
  line-height: 2;
  position: relative;
  top: 3px;
}
li {
  list-style-type: none;
}
.menuLink {
  text-align: right;
  position: relative;
  top: 0px;
  width: 100% !important;
}
.menuLink > a {
  /* padding: 4px 0 0 3em; */
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  color: black!important;
  padding-right: 0px;
  padding-left: 0px;
}
.menuLink > a:hover {
  /* padding: 4px 0 0 3em; */
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  color: #003C52!important;
  padding-right: 0px;
  padding-left: 0px;
}
.tourLogo {
  max-width: 70px !important;
  margin-top: -3px;
  padding-left: 0;
  max-height: 34px !important;
  width: initial !important;
}
@media only screen and (max-width: 768px) {
  .groupDropdown {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  p.filterTitle {
    font-weight: 600;
    font-size: 17px;
    color: #003C52;
    margin-top: 1rem;
  }
  .sections {
    padding: 10px 0;
  }
  span.FirstBreadCrum {
    font-size: 14px;
    position: relative;
    top: 2px;
    white-space: nowrap;
  }
  img.tourLogo.hideMob {
    display: none;
  }
  li.nav-item.menuLink.righMove {
    text-align: left;
  }
  ::v-deep .menuLink > a {
    padding: 0px;
    text-transform: capitalize;
    font-size: 10pt;
    font-weight: 500;
    color: black!important;
    padding-bottom: 10px;
  }
  ::v-deep .menuLink {
    text-align: left;
    position: relative;
    top: 7px;
  }
  ::v-deep .MobMiniMen {
    display: block;
    text-align: left;
  }
  ::v-deep .titleMini-sec {
    width: 100%;
    max-width: 100%;
    flex: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    overflow: scroll;
  }
  ::v-deep .card {
    height: 250px;
    width: 100%;
  }
}
</style>
