var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_vm._l((_vm.futureTour),function(upComing,index){return _c('b-col',{key:index,staticClass:"upEvents",attrs:{"lg":"4","sm":"12"}},[_c('div',{staticClass:"box"},[_c('b-card',{attrs:{"tag":"article"}},[_c('b-link',{staticClass:"noHover",attrs:{"to":{
            name: 'tournament',
            query: { id: _vm.seasonCode, code: upComing.code, tour: _vm.tour },
          }}},[_c('b-card-text',[(upComing.sponsor_logo !== '')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":upComing.sponsor_logo}})],1):(_vm.tour === 'aus')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":_vm.config.VUE_APP_ASSETS+'2021/03/PGA_TOUR_LOGO_PRI_RGB_POS.png'}})],1):(_vm.tour === 'snr')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":_vm.config.VUE_APP_ASSETS+'2021/08/PGA_LT_LOGO_RGB_POS.png'}})],1):(_vm.tour === 'all')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":_vm.config.VUE_APP_ASSETS+'2021/04/PGA_PAS_ADI_LOGO_RGB_POS.png'}})],1):(_vm.tour === 'trn')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":_vm.config.VUE_APP_ASSETS+'2021/03/PGA_CORP_LOGO_RGB_POS.png'}})],1):(_vm.tour === 'ppc')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":_vm.config.VUE_APP_PPC_LOGO+'2021/8133/8133.jpg'}})],1):_vm._e(),_c('p',{staticClass:"date"},[_vm._v(" "+_vm._s(upComing.dates)+" ")]),_c('p',{staticClass:"name"},[_vm._v(" "+_vm._s(upComing.full_name)+" ")]),_c('p',{staticClass:"course"},[_vm._v(" "+_vm._s(upComing.course)+" ")])])],1),(Array.isArray(upComing.reports.reports_entry))?[_c('b-row',{staticClass:"reportTag"},_vm._l((upComing.reports.reports_entry),function(report,index){return _c('b-col',{key:index,attrs:{"cols":"3"}},[[_c('b-nav-item',{staticClass:"reportButton",attrs:{"to":{
                      name: 'reports',
                      query: {
                        url: report.report_url,
                        id: _vm.seasonCode,
                        code: upComing.code,
                        tour: _vm.config.VUE_APP_URL_TOUR,
                        title: report.report_title
                      },
                    }}},[_c('span',{staticClass:"LinkNames",style:({'color': _vm.Primary_color})},[_c('span',{staticClass:"nameReport"},[(report.report_title.includes('Entries'))?[_vm._v("Entries ")]:_vm._e()],2),_c('span',{staticClass:"nameReport"},[(report.report_title.includes('Draw'))?[_vm._v("Draw ")]:_vm._e()],2),_c('span',{staticClass:"nameReport"},[(report.report_title.includes('Score'))?[_vm._v("Score ")]:_vm._e()],2),_c('span',{staticClass:"nameReport"},[(report.report_title.includes('Result'))?[_vm._v("Results ")]:_vm._e()],2),_c('img',{staticClass:"rightLittleArrow",attrs:{"src":"https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/07/chevron-right-copy.png"}})])])]],2)}),1)]:_vm._l((upComing.reports),function(report,index){return _c('div',{key:index},[(report.report_title == 'Tournament Entries')?[_c('b-nav-item',{staticClass:"reportButton",attrs:{"to":{
                      name: 'reports',
                      query: {
                        url: report.report_url,
                        id: _vm.seasonCode,
                        code: upComing.code,
                        tour: _vm.config.VUE_APP_URL_TOUR,
                        title: report.report_title
                      },
                    }}},[_c('span',{staticClass:"LinkNames",style:({'color': _vm.Primary_color})},[_vm._v(" Entries "),_c('img',{staticClass:"rightLittleArrow",attrs:{"src":"https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/07/chevron-right-copy.png"}})])])]:[_c('b-nav-item',{staticClass:"reportButton",attrs:{"to":{
                      name: 'reports',
                      query: {
                        url: report.report_url,
                        id: _vm.seasonCode,
                        code: upComing.code,
                        tour: _vm.config.VUE_APP_URL_TOUR,
                        title: report.report_title
                      },
                    }}},[_c('span',{staticClass:"LinkNames",style:({'color': _vm.Primary_color})},[_vm._v(" "+_vm._s(report.report_title)+" "),_c('img',{staticClass:"rightLittleArrow",attrs:{"src":"https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/07/chevron-right-copy.png"}})])])]],2)})],2)],1)])}),_vm._l((_vm.extraCol),function(extra,index){return _c('b-col',{key:index})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }