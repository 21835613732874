<template>
<div>
  <template v-if="isMobile(true)">
    <b-row class="bkRowColour" v-bind:style="{ 'background-color':  Primary_color}">
      <b-col lg="12">Tournament Schedule Listings</b-col>
    </b-row>
    <b-row v-for="(tourn, index) in filteredList" :key="index" class="rowDisplay" :style="currentTours" :class="{currentLiveTour : tourn.code != currentCode} " v-show="tourn.full_name.toUpperCase().includes(filter) || tourn.full_name.toLowerCase().includes(filter) || tourn.course.toLowerCase().includes(filter) || tourn.full_name.toUpperCase().includes(filter) || tourn.course.toUpperCase().includes(filter) ">
      <b-link class="linkrow" :to="{
          name: 'tournament',
          query: { id: id, code: tourn.code, tour: tour },
        }">
        <b-col cols="4" lg="4">
            <span v-if="tourn.sponsor_logo !== ''">
              <b-img class='sponLogoMob' :src='tourn.sponsor_logo'></b-img>
            </span>
            <span v-else-if="tour === 'aus'">
              <b-img class='sponLogoMob' :src="config.VUE_APP_ASSETS+'2021/03/PGA_TOUR_LOGO_PRI_RGB_POS.png'"></b-img>
            </span>
            <span v-else-if="tour === 'snr'">
              <b-img class='sponLogoMob' :src="config.VUE_APP_ASSETS+'2021/08/PGA_LT_LOGO_RGB_POS.png'"></b-img>
            </span>
            <span v-else-if="tour === 'all'">
              <b-img class='sponLogoMob' :src="config.VUE_APP_ASSETS+'2021/04/PGA_PAS_ADI_LOGO_RGB_POS.png'"></b-img>
            </span>
            <span v-else-if="tour === 'trn'">
              <b-img class='sponLogoMob' :src="config.VUE_APP_ASSETS+'2021/03/PGA_CORP_LOGO_RGB_POS.png'"></b-img>
            </span>
            <span v-else-if="tour === 'ppc'">
              <b-img class='sponLogoMob' :src="config.VUE_APP_PPC_LOGO+'2021/8133/8133.jpg'"></b-img>
            </span>
        </b-col>
        <b-col cols="8" lg="8">
            <span class="dates_pos mobSection">
              {{tourn.dates}}
            </span>
            <span class="completedSpan mobSection" v-if="tourn.winner_name.length > 0">
              Completed
            </span>
            <span class="fullName mobSection">
              <h4 class="fullnameH4 mobSection">{{ tourn.full_name }}</h4>
            </span>
            <span class="courseInfo mobSection">{{ tourn.course }}</span>
            <span class="defending_champ mobSection" v-if="tourn.winner_name.length > 0">
              {{tourn.winner_name}}
            </span>
            <span class="defending_champ mobSection" v-else>
              {{tourn.previous_winners.previous_winners_entry.cc_name_1}}
            </span>
            <span class="totalPrize_fund mobSection">
              {{tourn.total_prize_fund}}
            </span>
        </b-col>
      </b-link>
    </b-row>
  </template>

  <template v-else>
    <b-row class="bkRowColour" v-bind:style="{ 'background-color':  Primary_color}">
      <b-col lg="1"></b-col>
      <b-col lg="3">Dates</b-col>
      <b-col lg="4">Tournament</b-col>
      <b-col lg="2">Defending Champion</b-col>
      <b-col lg="2">Prize Money</b-col>
    </b-row>
    <b-row v-for="(tourn, index) in data" :key="index" class="rowDisplay" :style="currentTours" :class="{currentLiveTour : tourn.code != currentCode}" v-show="tourn.full_name.toLowerCase().includes(filter) || tourn.course.toLowerCase().includes(filter) || tourn.full_name.toUpperCase().includes(filter) || tourn.course.toUpperCase().includes(filter) ">
      <b-link class="linkrow" :to="{
          name: 'tournament',
          query: { id: id, code: tourn.code, tour: tour },
        }">
        <b-col lg="1">
            <span v-if="tourn.sponsor_logo !== ''">
              <b-img class='sponLogo' :src='tourn.sponsor_logo'></b-img>
            </span>
            <span v-else-if="tour === 'aus'">
              <b-img class='sponLogo' :src="config.VUE_APP_ASSETS+'2021/03/PGA_TOUR_LOGO_PRI_RGB_POS.png'"></b-img>
            </span>
            <span v-else-if="tour === 'snr'">
              <b-img class='sponLogo' :src="config.VUE_APP_ASSETS+'2021/08/PGA_LT_LOGO_RGB_POS.png'"></b-img>
            </span>
            <span v-else-if="tour === 'all'">
              <b-img class='sponLogo' :src="config.VUE_APP_ASSETS+'2021/04/PGA_PAS_ADI_LOGO_RGB_POS.png'"></b-img>
            </span>
            <span v-else-if="tour === 'trn'">
              <b-img class='sponLogo' :src="config.VUE_APP_ASSETS+'2021/03/PGA_CORP_LOGO_RGB_POS.png'"></b-img>
            </span>
            <span v-else-if="tour === 'ppc'">
              <b-img class='sponLogo' :src="config.VUE_APP_PPC_LOGO+'2021/8133/8133.jpg'"></b-img>
            </span>
        </b-col>
        <b-col lg="3">
            <span class="dates_pos">
              {{tourn.dates}}
            </span>
            <span class="completedSpan" v-if="tourn.winner_name.length > 0">
              Completed
            </span>
        </b-col>
        <b-col lg="4">
          <template v-if="lastCodeCalled == tourn.code">
          <span class="fullName" :id="lastCodeCalled">
            <h4 class="fullnameH4" @click="getID(tourn.code)" :style="{'color': Primary_color}">{{ tourn.full_name }}</h4>
          </span>
          <span class="courseInfo">{{ tourn.course }}</span>
          </template>
          <template v-else>
            <span class="fullName">
              <h4 class="fullnameH4" @click="getID(tourn.code)" :style="{'color': Primary_color}">{{ tourn.full_name }}</h4>
            </span>
            <span class="courseInfo">{{ tourn.course }}</span>
          </template>
        </b-col>
        <b-col lg="2">
            <span class="defending_champ" v-if="tourn.winner_name.length > 0">
              {{tourn.winner_name}}
            </span>
            <span class="defending_champ" v-else>
              {{tourn.previous_winners.previous_winners_entry.cc_name_1}}
            </span>
        </b-col>
        <b-col lg="2">
            <span class="totalPrize_fund">
              {{tourn.total_prize_fund}}
            </span>
        </b-col>
      </b-link>
    </b-row>
  </template>
</div>
</template>

<script>
import axios from "axios";
export default {
  name: "scheduleTable",
  props: ["id", "seasonCode", "currentCode", "fields", "data", 'tour', 'filter', 'config', 'lastCodeCalled', 'search'],
  data() {
    return {
      box:'box',
      Primary_color: process.env.VUE_APP_PRIMARY_COLOUR,
    }
  },
  components: {
  },
  methods: {
    backBtn:function(){
      console.log('IN')
      var el = document.getElementById(this.lastCodeCalled);
      el.scrollIntoView(true);
    },
    getID:function(code) {
        var cardData = {};
        console.log("cardData");
        console.log(cardData);
        for (var i of this.data) {
          if (i.code == code) {
            this.cardData = i;
            console.log(this.cardData.code)
            sessionStorage.setItem("TournCode", this.cardData.code);
            break;
          }
        }

    },
    isMobile() {
      if (screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.live_scoring === 'Y') return 'live'
    }
  },
  computed: {
    filteredList() {
      return this.data.filter(tourn => {
        return tourn.full_name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    currentTours() {
      return {
        "border-left": '15px solid' + process.env.VUE_APP_PRIMARY_COLOUR,
        "border-right": '15px solid' + process.env.VUE_APP_PRIMARY_COLOUR,
      };
    }
  },
  create(){
    // window.addEventListener("load", () => this.scrolldiv());
  },
  mounted() {
    const IsItABackButton = window.popStateDetected
    window.popStateDetected = true
    if (IsItABackButton == true) { 
      console.log('true')
      this.backBtn(); 
    } else {
      console.log('false')
      sessionStorage.clear("TournCode")
    }

    // if (sessionStorage.getItem("TournCode") === null) {
    //   console.log('IN')
    // } else{
    //   this.backBtn(); 
    // }
    axios
      .get(process.env.VUE_APP_WPAPI_URL+"wp/v2/" + 'scheduleads' + "/")
      .then((response) => {
        this.scheduleadimage = response.data[0].acf.ad_image;
        console.log("this.schedule_ad_image -- line 142")
        console.log(this.scheduleadimage)
      });
  },
};
</script>

<style scoped>
::v-deep #app {
scroll-behavior: smooth;
}
span.completedSpan.mobSection {
  padding-bottom: 5px;
  padding-top: 0px;
}
span.dates_pos.mobSection {
  padding-bottom: 5px;
}
.mobSection {
  display: block;
}
.row.rowDisplay.currentTour {
  /* background: #dcd6d6; */
  border-left: 15px solid #fff;
  border-right: 15px solid #fff;
}
.currentLiveTour {
  background-color: #fff!important;
  border-left: 15px solid #fff!important;
  border-right: 15px solid #fff!important;
}
.linkrow {
  width: 100%;
  display: inline-flex;
  color: #000!important;
}
.linkrow:hover {
  text-decoration: none;
}
.bkRowColour {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  padding-top: 7px;
  padding-bottom: 6px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
}
.rowDisplay:hover {
  -webkit-box-shadow: rgba(45, 45, 45, .05) 0 2px 2px, rgba(49, 49, 49, .05) 0 4px 4px, rgba(42, 42, 42, .05) 0 8px 8px, rgba(32, 32, 32, .05) 0 16px 16px, rgba(49, 49, 49, .05) 0 32px 32px, rgba(35, 35, 35, .05) 0 64px 64px;
  box-shadow: 0 2px 2px rgba(45, 45, 45, .05), 0 4px 4px rgba(49, 49, 49, .05), 0 8px 8px rgba(42, 42, 42, .05), 0 16px 16px rgba(32, 32, 32, .05), 0 32px 32px rgba(49, 49, 49, .05), 0 64px 64px rgba(35, 35, 35, .05);
  -webkit-transform: translate3D(0, -4px, 0);
  transform: translate3D(0, -2px, 0);
}
.rowDisplay {
  font-size: 14px;
  /* border-radius: 3px; */
  overflow: hidden;
  -webkit-box-shadow: 0 3px 4px 0 rgba(35, 35, 35, .1), 0 0 1px 0 rgba(10, 31, 68, .08);
  box-shadow: 0 3px 4px 0 rgba(35, 35, 35, .1), 0 0 1px 0 rgba(10, 31, 68, .08);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: opacity .2s ease-out, -webkit-box-shadow .3s ease-out, -webkit-transform .3s ease-out;
  transition: opacity .2s ease-out, -webkit-box-shadow .3s ease-out, -webkit-transform .3s ease-out;
  transition: box-shadow .3s ease-out, transform .3s ease-out, opacity .2s ease-out;
  transition: box-shadow .3s ease-out, transform .3s ease-out, opacity .2s ease-out, -webkit-box-shadow .3s ease-out, -webkit-transform .3s ease-out;
  border: 0.6px solid rgba(0, 0, 0, 0.125);
  padding-top: 15px;
  padding-bottom: 15px;
  background: #fff;
  /* margin-bottom: 25px; */
  margin-bottom: 15px;
  margin-left: 0px;
  margin-right: 0px;
  display: block;
}
::v-deep table.scheduleTR > thead {
  display: none;
}
span.completedSpan:hover {
  color: #00b588;
}
h4.fullnameH4 {
  font-size: 14px;
  font-weight: 600;
  color: #003C52;
}
span.completedSpan {
  display: block;
  position: relative;
  top: 0px;
  color: #000;
}
span.fullName {
  color: #01285e;
  font-weight: 700;
}
span.courseInfo {
  display: block;
  /* color: #01285e; */
  font-weight: 300;
}
.defending_champ {
  position: relative;
  top: 00px;
  font-weight: 300;
}
.dates_pos {
  position: relative;
  top: 0px;
  font-weight: 300;
}
.totalPrize_fund {
  position: relative;
  top: 00px;
  color: #000;
  font-weight: 300;
}
::v-deep td.tableTh:nth-child(3)>li>a{
  position: relative;
  top: -6px;
}
.schedTable {
  padding: 0px;
  margin-top: 2em;
}
.title{
  background-color: #00433e!important;
  color: white;
}
::v-deep .nav-link {
  padding-left: 0px;
}
::v-deep .tableTh > div{
  position: relative;
  top: 0px;
}
::v-deep table>tbody>tr>td{
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 0px solid #ddd;
  border-right: none!important;
  padding: 0.8em 0.5em 0.8em 1em!important;
  cursor: pointer;
  font-size: 13px;
  text-align: left;
  color: #000;
}
::v-deep table>thead>tr>th {
  vertical-align: bottom;
  border-bottom: 0px solid #ddd;
  font-weight: 300;
  color: white;
  font-size: 13px;
  text-transform: capitalize;
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  background: #00433E;
  color: #ffffff!important;
  text-align: left;
  z-index: 2;
}
table{
  width: 100%;
}
.sponLogo{
  max-height: 75px;
  max-width: 75px;
  /* margin-top: 10px; */
  /* margin-top: 20px; */
}
.sponLogoMob {
  max-height: 75px;
  max-width: 75px;
  margin: auto;
  display: block;
}
::v-deep .box {
    top: 0px;
    border-radius: 4px;
    position: relative;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    border-radius: 3px;
    overflow: hidden;
    -webkit-box-shadow: 0 3px 4px 0 rgba(35, 35, 35, .1), 0 0 1px 0 rgba(10, 31, 68, .08);
    box-shadow: 0 3px 4px 0 rgba(35, 35, 35, .1), 0 0 1px 0 rgba(10, 31, 68, .08);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: opacity .2s ease-out, -webkit-box-shadow .3s ease-out, -webkit-transform .3s ease-out;
    transition: opacity .2s ease-out, -webkit-box-shadow .3s ease-out, -webkit-transform .3s ease-out;
    transition: box-shadow .3s ease-out, transform .3s ease-out, opacity .2s ease-out;
    transition: box-shadow .3s ease-out, transform .3s ease-out, opacity .2s ease-out, -webkit-box-shadow .3s ease-out, -webkit-transform .3s ease-out;
    border-top: 15px solid #fff;
    border-bottom: 15px solid #fff;
    background: #fff;
}

::v-deep .box:hover {
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  background-color: white;
  color: #01285e;
}
h4.fullnameH4.mobSection {
  margin-bottom: 3px;
}
</style>
